<template >
<div>
  <router-view />
</div>
</template>

<script>

export default {
  name: 'orderlist',
  components: {
  }
}
</script>
<style lang="less" scoped>

</style>
